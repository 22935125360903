import React, { Fragment } from "react"
import RecommendationsForm from "../components/RecommendationsForm/RecommendationsForm"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import { Router } from "@reach/router"
const RecommendationsPage = () => {
  return (
    <Router>
      <RecommendationsForm path="/recommendations/:recommendationId" />
    </Router>
  )
}

export default () => <RecommendationsPage />
